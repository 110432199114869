<template>
  <v-container>
    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>
    <v-card flat class="detailCard pa-8 rounded-xl mt-6">
    <v-row class="pt-4 align-center">
      <v-col cols="12" md="6" v-if="!send && loaded">
        <h1 class="pb-3">Verbesserung vorschlagen</h1>
        <p>Teile uns gerne Deine Wünsche für Wedding-Flow mit. Wir freuen uns darauf Features zu entwickeln, die Du benötigst.</p>
        <h4 class="pt-4">Verbesserung vorschlagen</h4>
        <v-row>
          <v-col>
            <v-text-field
                label="Betreff"
                v-model="subject">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
          <v-textarea
              label="Beschreibung / Optimale Vorstellung"
              v-model="description"
          ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-btn
          class="primary"
          @click="saveBugReport()"
          >Senden</v-btn>
        </v-row>
      </v-col>
      <v-col cols="12" md="6" v-else-if="!loaded">
        <Spinner></Spinner>
      </v-col>
      <v-col cols="12" md="6" class="text-center" v-else>
        <h3>Nachricht erfolgreich versendet.</h3>
      </v-col>

      <v-col cols="12" md="6" class=" pa-16 text-center">
        <v-img
            :src="require('@/assets/images/drawSVGs/teamwork.svg')"
            max width="350px"
            class="d-inline-flex"
        />
      </v-col>
    </v-row>

    </v-card>
  </v-container>
</template>

<script>

import {mapGetters} from "vuex";
import {success, msgObj} from "@/helper/helper";
const Message = () => import("@/components/generalUI/Message");
const Spinner = () => import("@/components/generalUI/Spinner");

export default {
  name: "Feedback",
  components: {
    Message,
    Spinner
  },
  computed: {
    ...mapGetters('auth', {
      user: 'user',
    }),
  },
  mounted() {
  },
  data() {
    return {
      email:"",
      subject: "",
      description:"",
      message: msgObj(),
      loaded: true,
      send: false
    }
  },
  methods: {
    saveBugReport(){
      this.loaded = false
      this.$store.dispatch("support/saveBugReport",{
        uid: this.user.id,
        email: this.email,
        subject: this.subject,
        description: this.description,
        bug: false,
        optimizing: true
      }).then(()=>{
        this.send = true
        this.message = success('Meldung erfolgreich versendet.')
      }).finally(()=>{
        this.loaded = true
      })
    }
  }
}
</script>

<style lang="sass" scoped>
</style>
<style scoped>
</style>
